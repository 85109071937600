import de from './src/locales/de.json'
import en from './src/locales/en.json'
import es_MX from './src/locales/es_MX.json'
import fr from './src/locales/fr.json'
import pt_BR from './src/locales/pt_BR.json'
import zh_Hant from './src/locales/zh_Hant.json'
import zh_Hans from './src/locales/zh_Hans.json'
import { useNavigatorLanguage } from '@vueuse/core'
export default defineI18nConfig(() => {
  const { language } = useNavigatorLanguage()
  return {
    legacy: false,
    locale: language.value as keyof ILanguages,
    fallbackLocale: 'en',
    messages: {
      de,
      en,
      es_MX,
      pt_BR,
      zh_Hant,
      zh_Hans,
    },
  }
})
